/* eslint-disable react-hooks/exhaustive-deps */
import { ThemeProvider } from "@emotion/react";
import { Send } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFileWord } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link as domLink, useParams } from "react-router-dom";
import AlternativeProcedureDialog from "../../components/AlternativeProcedureDialog";
import OwnedStockConfirmation from "../../components/Comfirmation/OwnedStockConfirmation";
import DefaultPaper from "../../components/DefaultPaper";
import DiffAdjustmentDialog from "../../components/DiffAdjustmentDialog";
import ConfirmationLetterForm from "../../components/Form/ConfirmationLetterForm";
import ContactForm from "../../components/Form/ContactForm";
import MailForm from "../../components/Form/MailForm";
import FormDialog from "../../components/FormDialog";
import TextFieldWithTooltip from "../../components/Input/TextFieldWithTooltip";
import ConfirmerSpreadsheet from "../../components/SpreadSheets/ConfirmerSpreadsheet";
import AddEditDialogTable from "../../components/Table/AddEditDialogTable";
import useApi, { httpClient } from "../../hooks/useApi";
import { infoTheme } from "../../style/theme";
import { confirmerDataMapper } from "../../utils/ConfirmerListHead";
import Constants from "../../utils/Constants";
import getContactListHead, { contactData } from "../../utils/ContactListHead";
import getHistoryListHead, { historyData } from "../../utils/HistoryListHead";

const ConfirmationLetter = ({ title }) => {
  const params = useParams();
  const { connect } = useApi();
  const role = useSelector((state) => state.role.role);
  const [disableEdit, setDisableEdit] = useState(true);

  // 確認状情報取得
  const [fetchResult, setFetchResult] = useState();
  const fetchData = async () => {
    const result = await connect(() => {
      return httpClient.get(
        `../../api/${role}/GetConfirmation?id=${params.id}`
      );
    });
    setFetchResult(result?.data);
  };
  useEffect(() => {
    fetchData();
  }, [role]);

  // 表示制御
  const initializeState = () => {
    const confirmationLetterStatus =
      fetchResult?.data?.confirmationLetterStatus;
    const procedureStatus = fetchResult?.data?.procedureStatus;
    const hasEditAuthority =
      fetchResult?.data?.clientAuthority ===
        Constants.CLIENT_AUTHORITY_TYPE.EDIT ||
      fetchResult?.data?.clientAuthority ===
        Constants.CLIENT_AUTHORITY_TYPE.EDIT_APPROVAL;
    // 「ステータス」の表示制御
    setStatusDropdown(
      role === Constants.ROLE.AUDITOR &&
        confirmationLetterStatus !==
          Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
    );
    // 「確認結果」の表示制御
    setConfirmationResultDropdown(
      role === Constants.ROLE.AUDITOR &&
        confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.RESPONSED
    );
    // 「確認依頼メール送付」ボタンの表示制御
    setSendConfirmationButton(
      role === Constants.ROLE.AUDITOR &&
        fetchResult?.data?.sendingMethod ===
          Constants.SENDING_METHOD.EMAIL_OR_POST &&
        (confirmationLetterStatus ===
          Constants.CONFIRMATION_LETTER_STATUS.WAITING_FOR_SHIPMENT ||
          confirmationLetterStatus ===
            Constants.CONFIRMATION_LETTER_STATUS.SHIPPED)
    );
    // 「確認状ファイル出力」ボタンの表示制御
    setDlConfirmationButton(role === Constants.ROLE.AUDITOR);
    // 編集可否制御
    setDisableEdit(
      confirmationLetterStatus !==
        Constants.CONFIRMATION_LETTER_STATUS.REGISTERED ||
        procedureStatus !== Constants.PROCECURE_STATUS.REGISTERED ||
        (role === Constants.ROLE.CLIENT && !hasEditAuthority)
    );
  };
  useEffect(() => initializeState(), [role, fetchResult]);
  // 「ステータス」関連
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [confirmationLetterStatus, setConfirmationLetterStatus] = useState("");
  const [statusChangeDialogOpen, setStatusChangeDialogOpen] = useState(false);
  useEffect(() => {
    setConfirmationLetterStatus(fetchResult?.data?.confirmationLetterStatus);
  }, [fetchResult]);
  const onChangeConfirmationLetterStatus = (e, forceChange) => {
    const changeStatus = async (status) => {
      await connect(() => {
        return httpClient.post("../../api/auditor/UpsertConfirmation", {
          id: params.id,
          confirmationLetterStatus: status ?? e?.target?.value,
        });
      }, "保存しました");
      fetchData();
    };
    if (forceChange) {
      setConfirmationLetterStatus(
        Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
      );
      changeStatus(Constants.CONFIRMATION_LETTER_STATUS.REGISTERED);
      setStatusChangeDialogOpen(false);
    } else if (
      e.target.value === Constants.CONFIRMATION_LETTER_STATUS.REGISTERED
    ) {
      setStatusChangeDialogOpen(true);
    } else {
      setConfirmationLetterStatus(e.target.value);
      changeStatus();
    }
  };
  // 「確認結果」関連
  const [confirmationResultDropdown, setConfirmationResultDropdown] =
    useState(false);
  const [confirmationResult, setConfirmationResult] = useState("");
  useEffect(
    () => setConfirmationResult(fetchResult?.data?.confirmationResult),
    [fetchResult]
  );
  const onChangeConfirmationResult = async (e) => {
    await connect(() => {
      return httpClient.post("../../api/auditor/UpsertConfirmation", {
        id: params.id,
        confirmationResult: e.target.value,
      });
    }, "保存しました");
    fetchData();
  };
  // 「確認状メール送付」関連
  const [sendConfirmationButton, setSendConfirmationButton] = useState(false);
  // 「確認状ファイルF出力」関連
  const [dlConfirmationButton, setDlConfirmationButton] = useState(false);

  //確認状編集関連
  const [confirmLetterEditDialogOpen, setConfirmationLetterEditDialogOpen] =
    useState(false);
  const handleCloseConfirmationLetterEdit = (value) => {
    setConfirmationLetterEditDialogOpen(false);
    fetchData();
  };
  // 確認依頼メール一括送付関連
  const [mailDialogOpen, setMailDialogOpen] = useState();
  const handleCloseMailDialog = () => {
    setMailDialogOpen(false);
    fetchData();
  };

  // 代替手続関連
  const [alternativeProcedureDialogOpen, setAlternativeProcedureDialogOpen] =
    useState(false);
  const handleCloseAlternativeProcedureDialog = () => {
    setAlternativeProcedureDialogOpen(false);
  };
  const handleClickAlternativeProcedure = () => {
    setAlternativeProcedureDialogOpen(true);
  };
  // 差異調整手続き関連
  const [diffAdjustmentDialogOpen, setDiffAdjustmentDialogOpen] =
    useState(false);
  const handleDiffAdjustmentDialogClose = () => {
    setDiffAdjustmentDialogOpen(false);
  };
  const handleClickDiffAdjustment = (event) => {
    event.stopPropagation();
    setDiffAdjustmentDialogOpen(true);
  };

  return (
    <>
      <Typography variant="h6">{title}</Typography>

      {/* メニュー */}
      <DefaultPaper title={"メニュー"}>
        <Box
          sx={{
            "& > button": { marginRight: "1rem" },
            "& > div": { marginRight: "1rem" },
          }}
        >
          <FormControl>
            <InputLabel>ステータス</InputLabel>
            <Select
              value={confirmationLetterStatus}
              disabled={!statusDropdown}
              onChange={(e) => onChangeConfirmationLetterStatus(e)}
            >
              {fetchResult?.confirmationLetterStatusList?.map((row) => (
                <MenuItem value={row.code} key={row.code}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* 「ステータス」変更確認ダイアログ */}
          <Dialog
            maxWidth="xs"
            open={statusChangeDialogOpen}
            onClose={() => setStatusChangeDialogOpen(false)}
          >
            <DialogContent>
              ステータスを「登録」に変更すると、再度クライアントによる承認が必要となります。
              <br />
              よろしいですか？
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => onChangeConfirmationLetterStatus(e, true)}
              >
                変更
              </Button>
              <Button onClick={() => setStatusChangeDialogOpen(false)}>
                キャンセル
              </Button>
            </DialogActions>
          </Dialog>
          <FormControl>
            <InputLabel>確認結果</InputLabel>
            <Select
              value={confirmationResult}
              disabled={!confirmationResultDropdown}
              onChange={(e) => onChangeConfirmationResult(e)}
            >
              {fetchResult?.confirmationResultList?.map((row) => (
                <MenuItem value={row.code} key={row.code}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {sendConfirmationButton && (
            <Button
              onClick={() => setMailDialogOpen(true)}
              startIcon={<Send />}
            >
              確認状メール送付
            </Button>
          )}
          {dlConfirmationButton && (
            <Button
              download
              href={`../../api/auditor/DownloadConfirmationLetter?confirmationLetterId=${params.id}`}
              startIcon={<FaFileWord />}
            >
              確認状ファイル出力
            </Button>
          )}
          {/* <Button startIcon={<PictureAsPdf />}>監査調書PDF出力</Button>
          {type.id !== "receivablesAndDebs" && type.id !== "lawyer" && (
            <Button onClick={handleClickDiffAdjustment}>差異調整手続</Button>
          )}
          <Button onClick={handleClickAlternativeProcedure}>代替手続</Button> */}
          {/* <Button color="warning">確認除外</Button>
          <Button color="warning">確認除外の取り消し</Button> */}
        </Box>
      </DefaultPaper>

      {/* 概要 */}
      <DefaultPaper title="確認状概要">
        <Grid container>
          <ThemeProvider theme={infoTheme}>
            <Grid item xs={12}>
              <InputLabel>被監査企業</InputLabel>
              <Link
                color="inherit"
                noWrap
                to={`/${role}/company/${fetchResult?.data?.companyId}`}
                padding="16.5px 14px"
                component={domLink}
                sx={{
                  color: "#3f51b5",
                }}
              >
                {fetchResult?.data?.companyName}
              </Link>
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="基準日"
                value={fetchResult?.data?.referenceDateFormatted}
              />
            </Grid>
            <Grid item xs={9}>
              <InputLabel>確認手続名称</InputLabel>
              <Link
                color="inherit"
                noWrap
                to={`/${role}/procedure/${fetchResult?.data?.procedureId}`}
                padding="16.5px 14px"
                component={domLink}
                sx={{
                  color: "#3f51b5",
                }}
              >
                {fetchResult?.data?.procedureName}
              </Link>
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="リファー番号"
                value={fetchResult?.data?.referNo}
              />
              <TextFieldWithTooltip
                label="確認先名称"
                value={fetchResult?.data?.confirmationDestination}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="確認状種別"
                value={fetchResult?.data?.confirmationTypeLabel}
              />
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="回収希望日"
                value={fetchResult?.data?.desiredCollectionDateFormatted}
              />
              {/* <TextFieldWithTooltip
                label="回収状況"
                value={fetchResult?.data?.collectionStatusLabel}
              /> */}
            </Grid>
            <Grid item xs={3}>
              <TextFieldWithTooltip
                label="送付方法"
                value={fetchResult?.data?.sendingMethodLabel}
              />
              <Box textAlign="right">
                <Button
                  disabled={disableEdit}
                  onClick={() => setConfirmationLetterEditDialogOpen(true)}
                >
                  編集
                </Button>
              </Box>
            </Grid>
          </ThemeProvider>
          <Grid item xs={11} textAlign={"right"}></Grid>
        </Grid>
        {/* 確認状概要編集ダイアログ */}
        <FormDialog
          onClose={() => setConfirmationLetterEditDialogOpen(false)}
          open={confirmLetterEditDialogOpen}
          title={"確認状概要の編集"}
          maxWidth="sm"
        >
          <ConfirmationLetterForm
            targetData={fetchResult?.data}
            confirmationType={fetchResult?.data?.confirmationType}
            {...fetchResult}
            closeHandler={handleCloseConfirmationLetterEdit}
          />
        </FormDialog>
      </DefaultPaper>

      {/* 担当者一覧 */}
      <DefaultPaper title="確認先担当者一覧">
        <ConfirmerSpreadsheet
          targetData={confirmerDataMapper({ data: fetchResult?.confirmerList })}
          confirmationLetterId={params.id}
          confirmerAuthorityTypeList={fetchResult?.confirmerAuthorityTypeList}
          postDeterminedHandler={() => fetchData()}
          postSaveHandler={fetchData}
          // disabled={disableEdit}
        />
      </DefaultPaper>

      {/* 確認内容 */}
      <DefaultPaper title={"確認内容"}>
        {/* 所有株式 */}
        {fetchResult?.data?.confirmationType ===
          Constants.CONFIRMATION_LETTER_TYPE.OWNED_STOCK && (
          <OwnedStockConfirmation
            targetData={fetchResult?.data}
            presenceOrAbsenceList={fetchResult?.presenceOrAbsenceList}
            postSaveHandler={fetchData}
            readOnly={disableEdit}
          />
        )}
      </DefaultPaper>

      {/* 問合せ一覧 */}
      {false && (
        <DefaultPaper title="お問合せ一覧">
          <AddEditDialogTable
            testRows={contactData}
            headCells={getContactListHead()}
            listName="お問合せ"
            disablePagination
            disableAdd
            disableDelete
            enableKakuninButton
            editDialogContent={ContactForm}
          />
        </DefaultPaper>
      )}

      {/* 履歴一覧 */}
      {false && (
        <DefaultPaper title="履歴一覧">
          <AddEditDialogTable
            testRows={historyData}
            headCells={getHistoryListHead()}
            disablePagination
            disableAdd
            disableDelete
            disableEdit
            disableDialog
          />
        </DefaultPaper>
      )}

      {/* 手続情報確認依頼ダイアログ */}
      {/* <Dialog
        open={requestRegisterDialogOpen}
        onClose={handleCloseRequestRegisterDialog}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle align="center">確認状登録依頼</DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button onClick={() => messageDialogOpen(true)}>
              情報確認依頼
            </Button>
            <Button onClick={() => messageDialogOpen(true)}>
              確認結果登録
            </Button>
          </DialogActions>
          <Card sx={{ paddingLeft: "1rem" }}>
            <AddEditDialogTable
              testRows={companyInfoConfirmationRequestData}
              headCells={getCompanyInfoConfirmationRequestListHead()}
              disablePagination
              disableAdd
              disableDelete
              disableEdit
              disableDialog
            ></AddEditDialogTable>
          </Card>
        </DialogContent>
      </Dialog> */}

      {/* 確認依頼メッセージダイアログ */}
      {/* <FormDialog
        open={messageDialogOpen}
        title={"確認状情報確認依頼"}
        onClose={handleCloseMessageDialog}
        maxWidth="sm"
        fullWidth
      >
        <CompanyInfoComfirmationRequestMessageForm
          closeHandler={handleCloseMessageDialog}
        />
      </FormDialog> */}

      {/* メール送信ダイアログ */}
      <FormDialog
        open={mailDialogOpen}
        title="確認依頼メール送付"
        onClose={() => setMailDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <MailForm
          confirmationLetterId={params.id}
          closeHandler={handleCloseMailDialog}
        />
      </FormDialog>

      {/* 代替手続ダイアログ */}
      {false && (
        <AlternativeProcedureDialog
          open={alternativeProcedureDialogOpen}
          onClose={handleCloseAlternativeProcedureDialog}
        />
      )}

      {/* 差異調整手続きダイアログ */}
      {false && (
        <DiffAdjustmentDialog
          open={diffAdjustmentDialogOpen}
          onClose={handleDiffAdjustmentDialogClose}
        />
      )}
    </>
  );
};

export default ConfirmationLetter;
