import { TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function TextFieldWithTooltip(props) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (e) => {
    setValue(e.currentTarget.value);
  };

  return (
    <>
      <Tooltip title={value} placement="bottom">
        <TextField
          value={value}
          onChange={handleChange}
          {...props}
          inputProps={{
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
        />
      </Tooltip>
    </>
  );
}
