import { createTheme, responsiveFontSizes } from "@mui/material/styles";

//TODO: テーマの検討https://v4.mui.com/ja/customization/theming/
const theme = createTheme({
  palette: {
    primary: {
      50: "#e8eaf6",
      100: "#c5cbe9",
      200: "#9fa8da",
      300: "#7985cb",
      400: "#5c6bc0",
      500: "#3f51b5",
      600: "#394aae",
      700: "#3140a5",
      800: "#29379d",
      900: "#1b278d",
      A100: "#c6cbff",
      A200: "#939dff",
      A400: "#606eff",
      A700: "#4757ff",
      darkText: [50, 100, 200, 300, "A100", "A200"],
    },
  },
  //テキストスタイル
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
    // TODO:フォントサイズ等検討
    h1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
  },
  //TODO:要検討
  spacing: 4,
  //TODO:コンポーネントの個別設定
  components: {
    MuiToolbar: {
      defaultProps: {
        variant: "dense",
      },
      styleOverrides: {
        root: {
          minHeight: 40,
          height: 40,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          // 移動をクリック時に動かないように固定
          position: "static",
          transform: "none",
          transition: "none",
          // クリックを可能に
          pointerEvents: "auto",
          cursor: "pointer",
          // 幅いっぱいを解除
          display: "inline",
          alignSelf: "start",
          // タイポグラフィを指定
          fontSize: "0.875rem",
          // テーマの Composition を使えば以下のようにも書ける
          // base.typography.subtitle2
          maxWidth: "100%",
        },
        root: {
          color: "black",
          fontSize: "0.875rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // デフォルトだと、
          // ラベルをはみ出させるための小さなmarginがある
          marginTop: 0,
          // marginRight: "1rem",
          marginBottom: "3px",
        },
        input: {
          paddingTop: "3px",
          paddingBottom: "3px",
          height: "auto",
          minWidth: "12rem",
        },
        notchedOutline: {
          // デフォルトだと、 position が absolute、
          // ラベルをはみ出させるため上に少しの余白がある
          top: 0,
          legend: {
            // 内包された legend 要素によって、四角の左側の切り欠きが実現されているので、
            // 表示されないように。
            // (SCSS と同様にネスト記述が可能です。)
            display: "none",
          },
        },
        multiline: {
          paddingTop: "1px",
          paddingBottom: "1px",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 0,
          marginRight: 0,
        },
      },
    },
    MuiTextField: {
      defaultProps: { margin: "none" },
      styleOverrides: {
        root: {
          // TODO:フィールドの右にスペースを空ける
          marginRight: "1rem",
        },
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       marginBottom: "3px",
    //     },
    //   },
    // },
    MuiTypography: {
      defaultProps: {
        variant: "body2",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          marginTop: "1rem",
          marginRight: "1rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0.25rem",
        },
      },
      defaultProps: {
        dividers: true,
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            border: "1px solid rgba(198, 198, 198, 0.5)",
          }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            minWidth: "14rem",
          }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: "0",
            // borderBottom: "1px solid rgba(198, 198, 198, 0.5)",
          }),
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: { vertical: "top", horizontal: "center" },
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ".MuiAlert-filledSuccess": {
              backgroundColor: "#3f51b5",
            },
          }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            ".MuiAutocomplete-inputRoot": { padding: "0" },
            ".MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
              padding: "3px 14px",
            },
          }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => theme.unstable_sx({ whiteSpace: "pre-wrap" }),
      },
    },
    // ツールチップの位置調整
    MuiPopper: {
      defaultProps: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10], // [水平距離, 垂直距離]
            },
          },
        ],
      },
    },
  },
});

// 情報表示フィールド用のテーマ
export const infoTheme = createTheme(theme, {
  components: {
    MuiTextField: {
      defaultProps: {
        disabled: true,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& .MuiInputBase-input.Mui-disabled ~ fieldset": {
              border: "none",
              background: "rgba(198, 198, 198, 0.3)",
            },
          }),
      },
    },
    MuiSelect: {
      defaultProps: {
        disabled: true,
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
              minWidth: "11rem",
            },
            "& label.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            "& .MuiInputBase-input.Mui-disabled ~ fieldset": {
              border: "none",
              background: "rgba(198, 198, 198, 0.3)",
            },
          }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        disabled: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disabled: true,
      },
    },
  },
});

export default responsiveFontSizes(theme);
